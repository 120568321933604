export default {
  h1: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "66px",
    "@media(max-width:767px)": {
      fontSize: "50px",
    },
  },
  h2: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    "@media(max-width:767px)": {
      fontSize: "27px !important",
    },
  },
  h3: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "40px",
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    "@media(max-width:605px)": {
      fontSize: "16px",
    },
  },
  h5: {
    fontWeight: "700 !important",
    fontSize: 24,
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    "@media(max-width:605px)": {
      fontSize: "20px",
    },
  },
  h6: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    "@media(max-width:605px)": {
      fontSize: "12px",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
    fontSize: 10,
    
  },
  body2:{
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    "@media(max-width:605px)": {
      fontSize: "12px",
    },

  }
};
