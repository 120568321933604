import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "./layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/login",
    layout: HomeLayout,

    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Exchangestep/Exchange")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/ForgetPassword")),
  },
  // {
  //   exact: true,
  //   path: "/bot-settings",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/BotSettings")),
  // },
  {
    exact: true,
    path: "/connect-exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Exchangestep/ConnectExchange")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BuySell")),
  },
  {
    exact: true,
    path: "/bitfinex",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Bitfinex")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/VerifyOTP")),
  },
  {
    exact: true,
    path: "/signup",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/SignUp")),
  },
  {
    exact: true,
    path: "/sell-view",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Exchangestep/Sellview")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
