import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory } from "react-router-dom";

export default function LogoutModal({ Logout, setLogout }) {
  const history = useHistory();
  return (
    <Box>
      <Dialog
        open={Logout}
        onClose={() => setLogout(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Box pb={2}>
            <Box mb={2} align="center">
              <Typography
                variant="body2"
                style={{ fontWeight: "800 !important", color: "#000" }}
              >
                Are You Sure Want to Log out?
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Button variant="contained" color="secondary"onClick={()=>history.push("/")} >
                Yes
              </Button>&nbsp;&nbsp;

              <Button variant="contained" color="secondary" onClick={()=>setLogout(false)}>
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
