import React, { useState } from "react";
import { RiFacebookCircleFill } from "react-icons/ri";
import { Twitter } from "@material-ui/icons";
import TelegramIcon from "@material-ui/icons/Telegram";
import { RiDiscordFill } from "react-icons/ri";
import { GrYoutube } from "react-icons/gr";
import { RiInstagramFill } from "react-icons/ri";
//import { SiMedium } from "react-icons/si";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  List,
  Link,
  ListItem,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";

import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url(images/BannerBackground.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& .topFooterContent": {
      padding: "50px 0px 20px",
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
      },
    },
    "& .baseFooterContent": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "25px",
    },
    "& p": {
      marginBottom: "0px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#000000",
    },
  },

  footerText2: {
    alignItems: "center",
    flexDirection: "column",
    "& h6": {
      lineHeight: "3",
      color: "#4E547B",
      fontSize: "14px",
      cursor: "pointer",
    },
    "& div": {
      "& h4": {
        paddingBottom: "15px",
        marginTop: "8px",
      },
      "& p": {
        // color: "#898989",
        padding: "5px 0px",
        // fontFamily: "'Roboto', sans-serif",
        cursor: "pointer",
        fontSize: "15px",
        fontWeight: "400",
        wordBreak: "break-word",
        "& a": {
          color: "#000",
        },
      },
    },
  },

  footerlogoImageBox: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    "& figure": {
      margin: "0px",
      "& img": {
        width: "70px",
      },
    },
    "& h6": {
      marginTop: "15px",
    },
    "& p": {
      fontSize: "15px",

      lineHeight: "25px",
      textAlign: "left",
      paddingTop: "10px",
    },
    "& .SocialIcons": {
      width: "100%",
      marginTop: "15px",

      //textAlign: "left",
      // maxWidth: "248px",
      fontSize: "0px !important",
      "& a": {
        background: "linear-gradient(180deg, #00FBFB 0%, #0048A3 114.29%)",
        width: "15px",
        height: "15px",
        padding: "10px",
      },
    },
  },

  AddressText: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    "& figure": {
      margin: "0px",
      paddingRight: "10px",
      paddingTop: "8px",
    },
    "& p": {
      "& span": {
        fontWeight: "bold",
        color: "#000000",
      },
      "& a": {
        fontWeight: "bold",
        color: "#000000",
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Container>
        <Box className={classes.root}>
          <Box className="topFooterContent">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box className={classes.footerlogoImageBox}>
                  <RouterLink
                    to="/"
                    style={{ color: "#ffffff", textDecoration: "none" }}
                  >
                    <img src="images/logofotter.png" className="logoImg" />
                  </RouterLink>
                  <Typography variant="h6">
                    Follow Us on Social Media
                  </Typography>

                  <Box className="SocialIcons">
                    <IconButton target="_blank" style={{ marginLeft: "-14px" }}>
                      <RiFacebookCircleFill />
                    </IconButton>
                    <IconButton target="_blank">
                      <Twitter />
                    </IconButton>
                    <IconButton target="_blank">
                      <TelegramIcon />
                    </IconButton>
                    <IconButton target="_blank">
                      <RiDiscordFill />
                    </IconButton>
                    <IconButton target="_blank">
                      <GrYoutube />
                    </IconButton>
                    <IconButton target="_blank">
                      <RiInstagramFill />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Box className={classes.footerText2}>
                  <Box>
                    <Typography variant="h4">Features</Typography>

                    <Typography variant="h6">
                      <Link>Automatic Trading</Link>
                    </Typography>
                    <Typography variant="h6">
                      {" "}
                      <Link>Exchange Arbitrage</Link>
                    </Typography>
                    <Typography variant="h6">
                      <Link>Market Making Bot</Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Box className={classes.footerText2}>
                  <Box>
                    <Typography variant="h4">Resources</Typography>

                    <Typography variant="h6">
                      <Link>Get Started</Link>
                    </Typography>
                    <Typography variant="h6">
                      {" "}
                      <Link>Technical Indicator</Link>
                    </Typography>
                    <Typography variant="h6">
                      <Link>Supported Exchanges</Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Box className={classes.footerText2}>
                  <Box>
                    <Typography variant="h4">Company</Typography>

                    <Typography variant="h6">
                      <Link>About us</Link>
                    </Typography>
                    <Typography variant="h6">
                      {" "}
                      <Link>Contact us</Link>
                    </Typography>
                    <Typography variant="h6">
                      <Link>Privacy Policy</Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2} lg={2}>
                <Box className={classes.footerText2}>
                  <Box>
                    <Typography variant="h4">Links</Typography>

                    <Typography variant="h6">
                      <Link>Exchange</Link>
                    </Typography>
                    <Typography variant="h6">
                      {" "}
                      <Link>Pricing</Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box
        style={{ textAlign: "center", padding: "10px", background: "#221E29" }}
      >
        <Typography variant="body2" style={{ color: "#fff" }}>
          Copyright @ 2022 All Rights Reserved
        </Typography>
      </Box>
    </>
  );
}
